import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Login() {
  const [botId, setbotId] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (botId) {
      navigate(`/${botId}`);
    } else {
      alert('Please enter a valid ID');
    }
  };

  return (
    <div className="login-container">
      <h2>Enter your ID to start chatting</h2>
      <input
        type="text"
        value={botId}
        onChange={(e) => setbotId(e.target.value)}
        placeholder="Enter your ID"
      />
      <button onClick={handleLogin}>Chat starten</button>
    </div>
  );
}

export default Login;
